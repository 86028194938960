import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../../hooks/useRequest";
import Pagination from "../../Pagination/Pagination";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import Table from "../../Table/Table";
import { SearchInput, SearchSubmitButton } from "../../Form/Form";
import { addOneToDate } from "../../../util/fn";
import { OrderStatusObj } from "../../../util/helper";
import { BASEURL } from "../../../constant/api";

const api_path = "report/shipping-cost";
//const titleSingular = "customer";
const titlePlural = "Shipping Cost";

const OBJ_TABLE = {
  "Order Number": "order_number",
  "Customer ID": "customer_id",
  "Customer Name": "customer_name",
  "Shipping Address": "order_address",
  "Shipping Cost": "shipping_cost",
  "Total Order Value": "total",
  "Shipping Status": "current_status",
  "Shipping Date": "created_at",
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  order_number = "",
  customer_id = "",
  customer_name = "",
  shipping_cost = "",
  total_order_value = "",
  country = "",
  state = "",
  city = "",
  order_status = "",
  dateFrom = "1970-01-01",
  dateTo,
  export_excel = ""
) => {
  if (sortBy.length > 0) {
    if (sortBy == "registered on") {
      sortBy = "created_at";
    } else if (sortBy === "phone number") {
      sortBy = "contact";
    } else if (sortBy === "status") {
      sortBy = "isActive";
    } else if (sortBy === "updated on") {
      sortBy = "updated_at";
    }
  } else {
    sortBy = "created_at";
  }
  order = order.length > 0 ? order : "desc";

  dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());

  return `${api_path}?page=${page}&per_page=${per_page}&sort_by=${sortBy}&order=${order}&order_number=${order_number}&customer_id=${customer_id}&customer_name=${customer_name}&shipping_cost=${shipping_cost}&total_order_value=${total_order_value}&country=${country}&state=${state}&city=${city}&order_status=${order_status}&dateFrom=${dateFrom}&dateTo=${dateTo}&export_excel=${export_excel}`;
};

const ViewAllTrending = () => {
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "updated on",
    order: "desc",
  });

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const MySwal = withReactContent(Swal);

  const { records_per_page } = useSelector((state) => state.setting);
  const { roleId, permission } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    clearErrors,
    setError,
    setValue,
  } = useForm();

  const { request, response } = useRequest();
  const { request: countryRequest, response: countryResponse } = useRequest();
  const { request: stateRequest, response: stateResponse } = useRequest();
  const { request: cityRequest, response: cityResponse } = useRequest();

  const { request: requestSendCreds, response: responseSendCreds } =
    useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    countryRequest("GET", "shipping-country/data");
    document.title = `${titlePlural} - Single Vendor`;
  }, []);

  useEffect(() => {
    if (!countryResponse) return;
    setCountryList(countryResponse.countries);
  }, [countryResponse]);

  useEffect(() => {
    if (!stateResponse) return;
    setStateList(stateResponse.states);
  }, [stateResponse]);

  useEffect(() => {
    if (!cityResponse) return;
    setCityList(cityResponse.cities);
  }, [cityResponse]);

  useEffect(() => {
    if (response) {
      console.log(response);
      setDataList(response.data);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  useEffect(() => {
    if (responseSendCreds) {
      toast.success(responseSendCreds.message);
    }
  }, [responseSendCreds]);

  const handleCountryChange = (country) => {
    //console.log("con", country);
    setValue("state", "");
    setValue("city", "");
    setStateList([]);
    setCityList([]);
    if (country) {
      const selectCountry = JSON.parse(country);
      stateRequest("GET", `state/data?country_id=${selectCountry?.countryId}`);
      setValue("country", selectCountry.name);
    }
  };

  const handleStateChange = (state) => {
    //console.log("st", state);
    setValue("city", "");
    setCityList([]);
    if (state) {
      const selectState = JSON.parse(state);
      cityRequest("GET", `shipping-city/data?state_id=${selectState.stateId}`);
      setValue("state", selectState.name);
    }
  };

  const fetchMoreData = ({ selected }) => {
    const {
      order_number,
      customer_id,
      customer_name,
      shipping_cost,
      total_order_value,
      country,
      state,
      city,
      order_status,
      dateFrom,
      dateTo,
    } = getValues();

    setDataList([]);
    setPage(selected + 1);

    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        order_number,
        customer_id,
        customer_name,
        shipping_cost,
        total_order_value,
        country,
        state,
        city,
        order_status,
        dateFrom,
        dateTo
      )
    );
  };

  const onSearchHandler = (data) => {
    const {
      order_number,
      customer_id,
      customer_name,
      shipping_cost,
      total_order_value,
      country,
      state,
      city,
      order_status,
      dateFrom,
      dateTo,
    } = getValues();

    if (dateFrom && dateTo) {
      if (Moment(dateFrom).isAfter(dateTo)) {
        setError("dateTo", {
          type: "manual",
        });
        return;
      }
    }
    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        order_number,
        customer_id,
        customer_name,
        shipping_cost,
        total_order_value,
        country,
        state,
        city,
        order_status,
        dateFrom,
        dateTo
      )
    );
    setDataList([]);
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("order_number");
    resetField("customer_id");
    resetField("customer_name");
    resetField("shipping_cost");
    resetField("total_order_value");
    resetField("country");
    resetField("customCountry");
    resetField("state");
    resetField("customState");
    resetField("city");
    resetField("order_status");
    resetField("dateFrom");
    resetField("dateTo");

    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setDataList([]);
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const {
      order_number,
      customer_id,
      customer_name,
      shipping_cost,
      total_order_value,
      country,
      state,
      city,
      order_status,
      dateFrom,
      dateTo,
    } = getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        order_number,
        customer_id,
        customer_name,
        shipping_cost,
        total_order_value,
        country,
        state,
        city,
        order_status,
        dateFrom,
        dateTo
      )
    );
    setDataList([]);
    setPage(1);

    setPerPage(event.target.value);
  };

  /* const sortingHandler = (sortBy) => {
    const {
      product_id,
      product_name,
      type,
      color_name,
      size_name,
      parent_category,
      category_level_2,
      category_level_3,
      sales_count,
      view_count,
      sales_score,
      view_score,
      final_score,
    } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
          product_id,
          product_name,
          type,
          color_name,
          size_name,
          parent_category,
          category_level_2,
          category_level_3,
          sales_count,
          view_count,
          sales_score,
          view_score,
          final_score
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          product_id,
          product_name,
          type,
          color_name,
          size_name,
          parent_category,
          category_level_2,
          category_level_3,
          sales_count,
          view_count,
          sales_score,
          view_score,
          final_score
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  }; */

  const downloadExcelSearchQuery = () => {
    const {
      order_number,
      customer_id,
      customer_name,
      shipping_cost,
      total_order_value,
      country,
      state,
      city,
      order_status,
      dateFrom,
      dateTo,
    } = getValues();

    const path = searchQueryHandler(
      "",
      "",
      currentSort.sortBy,
      currentSort.order,
      order_number,
      customer_id,
      customer_name,
      shipping_cost,
      total_order_value,
      country,
      state,
      city,
      order_status,
      dateFrom,
      dateTo,
      1
    );

    return path.split("?")[1];
  };

  const InputFields = [
    {
      label: "Order Id",
      name: "order_number",
      required: false,
    },
    {
      label: "Customer Id",
      name: "customer_id",
      required: false,
    },
    {
      label: "Customer Name",
      name: "customer_name",
      required: false,
    },
    {
      label: "Shipping Cost",
      name: "shipping_cost",
      required: false,
    },
    {
      label: "Total Order Value",
      name: "total_order_value",
      required: false,
    },
    {
      isSelectInput: true,
      label: "Country",
      name: "customCountry",
      required: false,
      onChange: handleCountryChange,
      children: (
        <>
          <option value={""}>All</option>,
          {countryList.map((country) => (
            <option
              value={JSON.stringify({
                countryId: country.id,
                name: JSON.parse(country.countryName)["en"],
              })}
            >
              {JSON.parse(country.countryName)["en"]}
            </option>
          ))}
        </>
      ),
    },

    {
      isSelectInput: true,
      label: "State",
      name: "customState",
      required: false,
      onChange: handleStateChange,
      children: (
        <>
          <option value="">{"Select an option"}</option>
          {stateList.map((obj) => (
            <option
              key={obj.id}
              value={JSON.stringify({
                stateId: obj.id,
                name: JSON.parse(obj.stateName)["en"],
              })}
            >
              {" "}
              {JSON.parse(obj.stateName)["en"]}
            </option>
          ))}
        </>
      ),
    },

    {
      isSelectInput: true,
      label: "City",
      name: "city",
      required: false,
      children: (
        <>
          <option value="">{"Select an option"}</option>
          {cityList.map((obj) => (
            <option key={obj.id} value={JSON.parse(obj.cityName)["en"]}>
              {" "}
              {JSON.parse(obj.cityName)["en"]}
            </option>
          ))}
        </>
      ),
    },

    {
      isSelectInput: true,
      label: "Status",
      name: "order_status",
      required: false,
      children: (
        <>
          <option value={""}>All</option>
          <option value={"pending_action"}>Pending Action</option>
          <option value={"order_placed"}>Order Placed</option>
          <option value={"order_confirmed"}>Confirmed</option>
          <option value={"dispatched"}>Dispatched</option>
          <option value={"shipped"}>Shipped</option>
          <option value={"in_transit"}>In Transit</option>
          <option value={"delivered"}>Delivered</option>
          <option value={"Cancelled"}>Cancelled</option>
          <option value={"return_accepted"}>Returned</option>
        </>
      ),
    },

    {
      label: "Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
    },
    {
      label: "Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "'To Date' cannot be smaller than 'From Date'",
      },
    },
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title={titlePlural}
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>

                    <a
                      href={`${
                        BASEURL.PORT
                      }/${api_path}?${downloadExcelSearchQuery()}`}
                      className="btn btn-primary mr-2"
                    >
                      Export
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      //sortingHandler={sortingHandler}
                      mainData={dataList}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      onlyDate={{
                        created_at: "date",
                        updated_at: "dateTimeWithSuffix",
                        startDate: "dateTime",
                        endDate: "dateTime",
                      }}
                      renderAs={{
                        order_number: (value) => {
                          return value ? `#${value}` : "-";
                        },
                        customer_id: (value) => {
                          return value ? `#${value}` : "-";
                        },
                        current_status: (value) => {
                          return value ? OrderStatusObj[value] : "-";
                        },
                        order_address: (value) => {
                          if (!value) return <div> - </div>;

                          const address = JSON.parse(value);
                          return (
                            <div>
                              <p>
                                {address.address_1},{address.address_2}
                              </p>
                              <p>
                                {address.city},{address.state}
                              </p>
                              <p>{address.country}</p>
                            </div>
                          );
                        },
                        total: (value) => {
                          return value ? `USD ${value}` : "-";
                        },
                        shipping_cost: (value) => {
                          return `USD ${value}`;
                        },
                      }}
                      dontShowSort={[
                        "Order Number",
                        "Customer ID",
                        "Customer Name",
                        "Order Date",
                        "Order Status",
                        "Total Amount",
                        "Coupon Discount",
                        "Shipping Country",
                      ]}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={dataList.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAllTrending;
