import {
  Dashboard,
  Settings,
  SystemManagement,
  Seeker,
  Order,
  CustomerManagement,
  VendorManagement,
  SubAdminManagement,
  ProductManagement,
  WarehouseManagement,
  Taxes,
  Groups,
  GroupTesting,
  Rating,
  RatingMenuSvg,
  DashboardProductMenuIcon,
} from "./Svg";

export const menu = [
  {
    name: "Dashboard",
    path: "/",
    Svg: Dashboard,
    path: "/",
    subMenu: [
      /* {
        name: "Session Dashboard",
        path: "/",
        highlight: ["/"],
        subHighlight: [],
        key: "1_1",
        subMenu: [],
      },
      {
        name: "E-commerce Dashboard",
        path: "/e-commerce-dashboard",
        highlight: ["/e-commerce-dashboard"],
        subHighlight: [],
        key: "1_2",
        subMenu: [],
      }, */
    ],
    highlight: ["/my-profile", "/change-password"],
    subHighlight: [],
    key: "1",
  },
  {
    name: "Customer Management",
    path: "/customers",
    Svg: CustomerManagement,
    subMenu: [],
    highlight: ["/company/add"],
    subHighlight: [
      "/company/view",
      "/company/edit",
      "/company/change-password",
    ],
    key: "2",
  },

  // {
  //   name: "Sub Admin Management",
  //   Svg: SubAdminManagement,
  //   subMenu: [
  //     {
  //       name: "Sub Admin",
  //       path: "/sub-admins",
  //       highlight: ["/sub-admin/add"],
  //       subHighlight: [
  //         "/sub-admin/view",
  //         "/sub-admin/edit",
  //         "/sub-admin/change-password",
  //       ],
  //       key: "4_1",
  //       subMenu: [],
  //     },
  //     {
  //       name: "Admin Roles",
  //       path: "/admin-roles",
  //       highlight: ["/admin-role/add"],
  //       subHighlight: ["/admin-role/view", "/admin-role/edit"],
  //       key: "4_20",
  //       subMenu: [],
  //     },
  //   ],
  //   highlight: ["/sub-admin/add", "/admin-role/add"],
  //   subHighlight: [
  //     "/sub-admin/view",
  //     "/sub-admin/edit",
  //     "/sub-admin/change-password",
  //     "/admin-role/view",
  //     "/admin-role/edit",
  //   ],
  //   key: "4",
  // },

  // {
  //   name: "Group",
  //   Svg: GroupTesting,
  //   subMenu: [
  //     {
  //       name: "Vendors",
  //       path: "/group/vendors",
  //       highlight: ["/group/vendor/add"],
  //       subHighlight: ["/group/vendor/edit", "/group/vendor/view"],
  //       key: "31_21",
  //       subMenu: [],
  //     },
  //     {
  //       name: "Countries",
  //       path: "/group/countries",
  //       highlight: ["/group/country/add"],
  //       subHighlight: ["/group/country/edit", "/group/country/view"],

  //       key: "31_22",
  //       subMenu: [],
  //     },
  //     {
  //       name: "Customers",
  //       path: "/group/customers",
  //       highlight: ["/group/customer/add"],
  //       subHighlight: ["/group/customer/edit", "/group/customer/view"],
  //       key: "31_23",
  //       subMenu: [],
  //     },

  //     {
  //       name: "Suppliers",
  //       path: "/group/suppliers",
  //       highlight: ["/group/supplier/add"],
  //       subHighlight: ["/group/supplier/edit", "/group/supplier/view"],
  //       key: "31_24",
  //       subMenu: [],
  //     },

  //     {
  //       name: "Products ",
  //       path: "/group/products",
  //       highlight: ["/group/product/add"],
  //       subHighlight: ["/group/product/edit", "/group/product/view"],
  //       key: "31_25",
  //       subMenu: [],
  //     },
  //   ],
  //   highlight: [
  //     "/group/vendor/add",
  //     "/group/country/add",
  //     "/group/customer/add",
  //     "/group/supplier/add",
  //     "/group/product/add",
  //   ],
  //   subHighlight: [
  //     "/group/vendor/edit",
  //     "/group/vendor/view",
  //     "/group/country/edit",
  //     "/group/country/view",
  //     "/group/customer/edit",
  //     "/group/customer/view",
  //     "/group/supplier/edit",
  //     "/group/supplier/view",
  //     "/group/product/edit",
  //     "/group/product/view",
  //   ],
  //   key: "31",
  // },
  {
    name: "Home Page Management",
    Svg: ProductManagement,
    subMenu: [
      {
        name: "Slider",
        path: "/home/section-one",
        highlight: [
          "/home/section-one",
          "/home/section-one",
          "/home/section-one/add",
        ],
        subHighlight: ["/section-one/edit"],
        key: "13_1",
        subMenu: [],
      },
      {
        name: "Middle Slider",
        path: "/home/middle-slider",
        highlight: [
          "/home/middle-slider",
          "/home/middle-slider",
          "/home/middle-slider/add",
        ],
        subHighlight: ["/middle-slider/edit"],
        key: "13_1",
        subMenu: [],
      },
      {
        name: "Bottom Slider",
        path: "/home/bottom-slider",
        highlight: [
          "/home/bottom-slider",
          "/home/bottom-slider",
          "/home/bottom-slider/add",
        ],
        subHighlight: ["/bottom-slider/edit"],
        key: "13_1",
        subMenu: [],
      },
    ],
    highlight: ["/section-one", "/section-one", "/section-one/add"],
    subHighlight: ["/section-one/edit"],
    key: "13",
  },
  {
    name: "Newsletter Subscribers",
    path: "/newsletter-subscribers",
    Svg: CustomerManagement,
    highlight: ["/newsletter-subscribers", "/newsletter-subscribers"],
    subHighlight: ["/newsletter-subscribers"],
    subMenu: [],
  },

  {
    name: "System Management",
    Svg: SystemManagement,
    subMenu: [
      {
        name: "Cms Pages",
        path: "/cms",
        highlight: ["/cms/add"],
        subHighlight: ["/cms/view", "/cms/edit"],
        key: "12_1",
        subMenu: [],
      },
      // {
      //   name: "Seo Pages",
      //   path: "/seo-pages",
      //   highlight: ["/seo-page/add"],
      //   subHighlight: ["/seo-page/edit"],
      //   key: "12_10",
      //   subMenu: [],
      // },
      {
        name: "Email Templates",
        path: "/email-template",
        highlight: ["/email-template/add"],
        subHighlight: ["/email-template/edit"],
        key: "12_20",
        subMenu: [],
      },
      {
        name: "Notification Templates",
        path: "/notification-template",
        highlight: ["/notification-template/add"],
        subHighlight: ["/notification-template/edit"],
        key: "12_20",
        subMenu: [],
      },
      {
        name: "Seo Tags",
        path: "/seo-pages",
        highlight: ["/seo-page/add"],
        subHighlight: ["/seo-page/edit"],
        key: "12_21",
        subMenu: [],
      },
      {
        name: "Email Logs",
        path: "/email-logs",
        highlight: [],
        subHighlight: [],
        key: "12_30",
        subMenu: [],
      },
      {
        name: "FAQs",
        path: "/faq",
        highlight: ["/faq/add"],
        subHighlight: ["/faq/edit", "/faq/view"],
        key: "12_40",
        subMenu: [],
      },
      // {
      //   name: "Languages",
      //   path: "/languages",
      //   highlight: ["/language/add"],
      //   subHighlight: [],
      //   key: "12_140",
      //   subMenu: [],
      // },

      {
        name: "Contact Us",
        path: "/contact-us",
        highlight: [],
        subHighlight: [],
        key: "12_200",
        subMenu: [],
      },
    ],
    highlight: [
      "/setting/prefix/Payment",
      "/system-images/add",
      "/country/add",
    ],
    subHighlight: [
      "/system-images/edit",
      "/seo-page/edit",
      "/country/edit",
      "/cities",
      "/taxes",
    ],
    key: "12",
  },
  {
    name: "Orders Management",
    path: "/order/all",
    Svg: Order,
    subMenu: [],
    highlight: ["/order/all", "/order/view"],
    subHighlight: ["/order/all", "/order/view"],
    key: "7",
  },
  {
    name: "Masters",
    Svg: SystemManagement,
    subMenu: [
      {
        name: "Title",
        path: "/master/title",
        highlight: ["/master/title/add"],
        subHighlight: ["/master/title/view", "/master/title/edit"],
        key: "12_1",
        subMenu: [],
      },
      {
        name: "Cancel Reason(User)",
        path: "/master/cancel-reason-user",
        highlight: ["/master/cancel-reason-user/add"],
        subHighlight: [
          "/master/cancel-reason-user/view",
          "/master/cancel-reason-user/edit",
        ],
        key: "12_1",
        subMenu: [],
      },
      {
        name: "Cancel Reason(Admin)",
        path: "/master/cancel-reason-admin",
        highlight: ["/master/cancel-reason-admin/add"],
        subHighlight: [
          "/master/cancel-reason-admin/view",
          "/master/cancel-reason-admin/edit",
        ],
        key: "12_1",
        subMenu: [],
      },
      {
        name: "Return Reason",
        path: "/master/return-reason",
        highlight: ["/master/return-reason/add"],
        subHighlight: [
          "/master/return-reason/view",
          "/master/return-reason/edit",
        ],
        key: "12_1",
        subMenu: [],
      },
    ],
    highlight: [],
    subHighlight: [],
  },
  {
    name: "Settings",
    Svg: Settings,
    subMenu: [
      {
        name: "Site Setting",
        path: "/setting/prefix/Site",
        highlight: [],
        subHighlight: [],
        subMenu: [],
        key: "13_2",
      },
      {
        name: "Reading Setting",
        path: "/setting/prefix/Reading",
        key: "13_3",
        highlight: [],
        subHighlight: [],
        subMenu: [],
      },
      {
        name: "Social Setting",
        path: "/setting/prefix/Social",
        key: "13_4",
        highlight: [],
        subHighlight: [],
        subMenu: [],
      },
      {
        name: "Contact Setting",
        path: "/setting/prefix/Contact",
        key: "13_5",
        highlight: [],
        subHighlight: [],
        subMenu: [],
      },
      {
        name: "Footer Setting",
        path: "/home/footer-text",
        key: "13_5",
        highlight: [],
        subHighlight: [],
        subMenu: [],
      },
    ],
    highlight: ["/setting", "/setting/add", "/employee-count/add"],
    subHighlight: ["/setting/edit", "/employee-count/edit"],
    key: "13",
  },
  {
    name: "Product Attributes",
    Svg: DashboardProductMenuIcon,
    subMenu: [
      {
        name: "Color",
        path: "/color",
        highlight: ["/color", "/color/add"],
        subHighlight: ["/color/edit"],
        subMenu: [],
        key: "14_2",
      },
      {
        name: "Size",
        path: "/size",
        key: "14_3",
        highlight: ["/size", "/size/add"],
        subHighlight: ["/size/edit"],
        subMenu: [],
      },
      {
        name: "Brand",
        path: "/brand",
        key: "14_4",
        highlight: ["/brand", "/brand/add"],
        subHighlight: ["/brand/edit"],
        subMenu: [],
      },
      {
        name: "Features",
        path: "/feature",
        key: "14_4",
        highlight: ["/feature", "/feature/add"],
        subHighlight: ["/feature/edit"],
        subMenu: [],
      },
      {
        name: "Categories",
        path: "/category",
        key: "14_5",
        highlight: ["/category", "/category/add"],
        subHighlight: [
          "/category/edit",
          "/category-level-2",
          "/category-level-3",
        ],
        subMenu: [],
      },
      {
        name: "Products",
        path: "/product",
        key: "14_5",
        highlight: ["/product", "/product/add"],
        subHighlight: ["/product/edit", "/product-details", "/product"],
        subMenu: [],
      },
      {
        name: "Shipping Countries",
        path: "/shipping-country",
        key: "14_5",
        highlight: ["/shipping-country", "/shipping-country/add"],
        subHighlight: ["/shipping-country/edit", "/state", "/tax", "/country"],
        subMenu: [],
      },
      {
        name: "Discount Code",
        path: "/coupon",
        key: "14_6",
        highlight: ["/coupon", "/coupon/add"],
        subHighlight: ["/coupon/edit", "/coupon-details"],
        subMenu: [],
      },
      {
        name: "Offers",
        path: "/offer",
        key: "14_8",
        highlight: ["/offer", "/offer/add"],
        subHighlight: ["/offer/edit"],
        subMenu: [],
      },
      {
        name: "Trending Product Setting",
        path: "/trending-product-setting",
        key: "14_7",
        highlight: [],
        subHighlight: [],
        subMenu: [],
      },
    ],
    highlight: [],
    subHighlight: [],
    key: "14",
  },
  {
    name: "Reports",
    Svg: Order,
    highlight: [],
    subHighlight: [],
    subMenu: [
      {
        name: "Trending Products",
        path: "/trending-products",
        key: "8",
        highlight: [],
        subHighlight: [],
        subMenu: [],
      },
      {
        name: "Orders Report",
        key: "8",
        highlight: [],
        subHighlight: [],
        subMenu: [
          {
            name: "Order Summary",
            path: "/report/order-summary",
            key: "8",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
          {
            name: "Order Details",
            path: "/report/order-details",
            key: "8",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
        ],
      },
      {
        name: "Notification Report",
        key: "16",
        highlight: [],
        subHighlight: [],
        subMenu: [
          {
            name: "Notifications",
            path: "/report/notifications",
            key: "16_1",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
          {
            name: "Notification Details",
            path: "/report/notification-details",
            key: "16_2",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
        ],
      },
      {
        name: "Quantity Reports",
        key: "9",
        highlight: [],
        subHighlight: [],
        subMenu: [
          {
            name: "Product Quantity Report Detail",
            path: "/report/product-variant-quantity",
            key: "9_1",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
          {
            name: "Product Quantity Report",
            path: "/report/product-quantity",
            key: "9_2",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
        ],
      },
      {
        name: "Shipping Reports",
        key: "9",
        highlight: [],
        subHighlight: [],
        subMenu: [
          {
            name: "Shipping Status",
            path: "/report/shipping-status",
            key: "9_1",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
          {
            name: "Shipping Cost",
            path: "/report/shipping-cost",
            key: "9_2",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
        ],
      },
      {
        name: "Stock Management",
        key: "10",
        highlight: [],
        subHighlight: [],
        subMenu: [
          {
            name: "Stock Level",
            path: "/report/product-stock-level",
            key: "10_1",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
          {
            name: "Stock Movement",
            path: "/report/product-stock-movement",
            key: "10_2",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
        ],
      },
      {
        name: "Returns and Refunds",
        key: "15",
        highlight: [],
        subHighlight: [],
        subMenu: [
          {
            name: "Return Requests",
            path: "/report/return-request",
            key: "10_1",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
          {
            name: "Refund Issued",
            path: "/report/refund-issued",
            key: "10_2",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
        ],
      },
      {
        name: "Discount",
        key: "11",
        highlight: [],
        subHighlight: [],
        subMenu: [
          {
            name: "Discount By Order",
            path: "/report/discount-by-order",
            key: "11_1",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
          {
            name: "Total Discount Applied",
            path: "/report/total-discount-applied",
            key: "11_2",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
        ],
      },
      {
        name: "Address Report",
        key: "11",
        highlight: [],
        subHighlight: [],
        subMenu: [
          {
            name: "Customer Address",
            path: "/report/customer-address",
            key: "11_1",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
        ],
      },
      {
        name: "Sales",
        key: "12",
        highlight: [],
        subHighlight: [],
        subMenu: [
          {
            name: "Sales Summary",
            path: "/report/sales-summary",
            key: "11_1",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
          {
            name: "Sales By Product",
            path: "/report/sales-by-product",
            key: "11_1",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
        ],
      },
      {
        name: "Product Waiting List",
        key: "12",
        highlight: [],
        subHighlight: [],
        subMenu: [
          {
            name: "Product Waiting List",
            path: "/report/product-waiting-list",
            key: "12_1",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
        ],
      },
      {
        name: "Product Feature",
        key: "13",
        highlight: [],
        subHighlight: [],
        subMenu: [
          {
            name: "Product Feature",
            path: "/report/product-feature",
            key: "13_1",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
        ],
      },
      {
        name: "Order History",
        key: "14",
        highlight: [],
        subHighlight: [],
        subMenu: [
          {
            name: "Order History",
            path: "/report/order-history",
            key: "14_1",
            highlight: [],
            subHighlight: [],
            subMenu: [],
          },
        ],
      },
      {
        name: "Transactions",
        path: "/report/transactions",
        subMenu: [],
        highlight: [],
        subHighlight: [],
        key: "15",
      },
    ],
  },
  {
    name: "Rating and Reviews",
    path: "/rating-review",
    Svg: RatingMenuSvg,
    subMenu: [],
    highlight: [],
    subHighlight: [],
    key: "15",
  },
];
