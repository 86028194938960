import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { SearchInput, SearchSubmitButton } from "../Form/Form";
import { addOneToDate } from "../../util/fn";

const apiName = "user";
const titleSingular = "customer";
const titlePlural = "Customer Management";

const OBJ_TABLE = {
  firstName: "first_name",
  lastName: "last_name",
  countryCode: "phone_number_code",
  contact: "phone_number",
  email: "email",
  "registered on": "created_at",
  "updated on": "updated_at",
  status: "isActive",
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  isActive = "",
  firstName = "",
  lastName = "",
  contact = "",
  email = "",
  dateFrom = "1970-01-01",
  dateTo
) => {
  if (sortBy.length > 0) {
    if (sortBy == "registered on") {
      sortBy = "created_at";
    } else if (sortBy === "phone number") {
      sortBy = "contact";
    } else if (sortBy === "status") {
      sortBy = "isActive";
    } else if (sortBy === "updated on") {
      sortBy = "updated_at";
    } else if (sortBy === "countryCode") {
      sortBy = "phone_number_code";
    }
  } else {
    sortBy = "created_at";
  }
  order = order.length > 0 ? order : "desc";

  dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());

  return `${apiName}/all?page=${page}&per_page=${per_page}&sort_by=${sortBy}&order=${order}&is_active=${isActive}&first_name=${firstName}&last_name=${lastName}&email=${email}&phone_number=${contact}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
};

const ViewAll = () => {
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "updated on",
    order: "desc",
  });

  const MySwal = withReactContent(Swal);

  const { records_per_page } = useSelector((state) => state.setting);
  const { roleId, permission } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    clearErrors,
    setError,
  } = useForm();

  const { request, response } = useRequest();

  const { request: requestChangeStatus, response: responseChangeStatus } =
    useRequest();
  const { request: requestSendCreds, response: responseSendCreds } =
    useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = `${titlePlural} - Single Vendor`;
  }, []);

  useEffect(() => {
    if (response) {
      setDataList(response.users);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  useEffect(() => {
    if (responseSendCreds) {
      toast.success(responseSendCreds.message);
    }
  }, [responseSendCreds]);

  useEffect(() => {
    if (responseChangeStatus) {
      const { id, newStatus } = responseChangeStatus;
      const newDataList = [...dataList];
      const indexToChange = newDataList.findIndex((list) => list.id == id);
      newDataList[indexToChange].isActive = newStatus;
      newDataList[indexToChange].is_active = +newStatus;
      toast.success(responseChangeStatus.message);
      setDataList(newDataList);
    }
  }, [responseChangeStatus]);

  useEffect(() => {
    if (responseDelete) {
      const { id } = responseDelete;
      let newDataList = [...dataList];
      newDataList = newDataList.filter((list) => list.id != id);
      setDataList(newDataList);
      setTotalDocuments(totalDocuments - 1);
      toast.success(responseDelete.message);
    }
  }, [responseDelete]);

  const fetchMoreData = ({ selected }) => {
    setDataList([]);
    const {
      isActive,
      firstName,
      lastName,
      contact,
      email,
      country,
      dateFrom,
      dateTo,
    } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        firstName,
        lastName,
        contact,
        email,
        country,
        dateFrom,
        dateTo
      )
    );
  };

  const deleteHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete this customer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestDelete("DELETE", `user/admin-delete-user`, { id });
      } else if (result.isDismissed) {
      }
    });
  };

  const onSearchHandler = (data) => {
    const {
      isActive,
      firstName,
      lastName,
      contact,
      email,
      country,
      dateFrom,
      dateTo,
    } = getValues();

    if (dateFrom && dateTo) {
      if (Moment(dateFrom).isAfter(dateTo)) {
        setError("dateTo", {
          type: "manual",
        });
        return;
      }
    }
    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        firstName,
        lastName,
        contact,
        email,
        dateFrom,
        dateTo
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("isActive");
    resetField("firstName");
    resetField("lastName");
    resetField("contact");
    resetField("email");
    resetField("country");
    resetField("dateFrom");
    resetField("dateTo");
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const {
      isActive,
      firstName,
      lastName,
      contact,
      email,
      country,
      dateFrom,
      dateTo,
    } = getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        firstName,
        lastName,
        contact,
        email,
        country,
        dateFrom,
        dateTo
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const {
      isActive,
      firstName,
      lastName,
      contact,
      email,
      country,
      dateFrom,
      dateTo,
    } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          newOrder,
          isActive,
          firstName,
          lastName,
          contact,
          email,
          country,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          isActive,
          firstName,
          lastName,
          contact,
          email,
          country,
          dateFrom,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const changeStatusHandler = (id) => {
    const data = dataList.find((list) => list.id == id);
    const status = !data.isActive;

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestChangeStatus("PUT", `${apiName}/status`, {
          id,
          status,
        });
      } else if (result.isDismissed) {
      }
    });
  };
  const sendCredentials = (id) => {
    requestSendCreds("POST", `${apiName}/send-credentials`, { id });
  };

  const InputFields = [
    {
      label: "First Name",
      name: "firstName",
      required: false,
    },
    {
      label: "Last Name",
      name: "lastName",
      required: false,
    },
    {
      label: "Contact",
      name: "contact",
      required: false,
    },
    {
      label: "Email",
      name: "email",
      required: false,
    },

    {
      isSelectInput: true,
      label: "Status",
      name: "isActive",
      required: false,
      children: (
        <>
          <option value="">{"Select an option"}</option>
          <option value={true}> {"Activated"}</option>
          <option value={false}>{"Deactivated"}</option>
        </>
      ),
    },
    {
      label: "Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
    },
    {
      label: "Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "'To Date' cannot be smaller than 'From Date'",
      },
    },
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title={titlePlural}
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>

                    <Link to="/customer/add" className="btn btn-primary">
                      Add New {titleSingular}
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={dataList}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      links={[
                        {
                          isLink: false,
                          name: "Deactivate",
                          click: changeStatusHandler,
                          title: "Click To Activate",
                          key: "2_3",
                        },
                        {
                          isLink: false,
                          name: "Activate",
                          click: changeStatusHandler,
                          title: "Click To Deactivate",
                          key: "2_3",
                        },
                        {
                          isLink: true,
                          to: "/customer/view",
                          name: "View",
                          extraData: true,
                          key: "2_4",
                        },
                        {
                          isLink: true,
                          to: "/customer/edit",
                          name: "Edit",
                          extraData: true,
                          key: "2_5",
                        },
                        {
                          isLink: false,
                          name: "Delete",
                          click: deleteHandler,
                          key: "2_6",
                        },
                        {
                          isLink: true,
                          to: "/customer/change-password",
                          name: "ChangePassword",
                          extraData: true,
                          title: "Change Password",
                          key: "2_7",
                        },

                        {
                          isLink: false,
                          name: "SendCreds",
                          click: sendCredentials,
                          title: "Send Credentials",
                          key: "2_8",
                        },
                      ]}
                      onlyDate={{
                        created_at: "date",
                        updated_at: "dateTimeWithSuffix",
                        startDate: "dateTime",
                        endDate: "dateTime",
                      }}
                      renderAs={{
                        phone_number_code: (value) => {
                          return value ? `+${value}` : "-";
                        },
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={dataList.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
