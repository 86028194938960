import React, { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
// import { SubTab, SubInput } from "./TabNInput";
// import { DevTool } from "@hookform/devtools";
import { useSelector } from "react-redux";

import { API } from "../../constant/api";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  RenderMultiLangInputFields,
  SubmitButton,
  CKEditorInput,
  SelectInput,
  Textarea,
  ReactSelectInputTwo,
  CustomSelectInput,
  CustomCKEditorInput,
} from "../Form/Form";
import "./product.css";
import {
  FILE_MAX_SIZE,
  filterVariants,
  getValueInLanguage,
} from "../../util/helper";
import EditImageUploader from "./components/EditImageUploader";
import { InvalidTabSvg } from "../../util/Svg";
import FormError from "../General/FormError";
import TempEditImageUploader from "./components/TempEditImageUploader";

const SubTab = ({ id, name, index, image }) => {
  return (
    <li className={`nav-item ${index > 0 ? "mr-3" : ""}`}>
      <a
        className={`nav-link ${index === 0 ? "active" : ""}`}
        data-toggle="tab"
        href={`#${id}_${index}`}
      >
        <>
          {false && image && (
            <span className="symbol symbol-20 mr-3">
              <img src={`${API.PORT}/${image}`} alt="" />
            </span>
          )}
          <span className="nav-text">{name}</span>
        </>
      </a>
    </li>
  );
};

const SubInput = ({
  id,
  index,
  errors,
  register,
  required,
  InputFields,
  code,
  control,
}) => {
  return (
    <div
      className={`tab-pane ${index === 0 ? "active" : ""}`}
      id={`${id}_${index}`}
      role="tabpanel"
    >
      <RenderMultiLangInputFields
        InputFields={InputFields}
        errors={errors}
        register={register}
        required={required}
        code={code}
        control={control}
      />

      <div className="row"></div>
    </div>
  );
};

const Edit = (props) => {
  const { id } = props.match.params;
  const ckEditorRef = useRef();
  //const [isCkContent, setIsCkContent] = useState(false);

  const [langDataIds, setLangDataIds] = useState({});

  const { languages } = useSelector((state) => state.setting);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    clearErrors,
    setError,
    control,
    unregister,
    reset,
    resetField,
  } = useForm({
    defaultValues: {
      variants: [],
    },
  });

  const [parentCategoryInput, setParentCategoryInput] = useState([]);

  const [categoryL2Input, setCategoryL2Input] = useState(null);
  const [categoryL3Input, setCategoryL3Input] = useState(null);
  const [colorsList, setColorsList] = useState([]);
  const [sizesList, setSizesList] = useState([]);
  const [featuresList, setFeaturesList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [coverImage, setCoverImage] = useState();
  const [images, setImages] = useState([]);
  const [showAddVarient, setShowAddVarient] = useState(false);
  const [inputVarients, setInputVariants] = useState([]);
  const [sizeImage, setSizeImage] = useState();
  const [productVideo, setProductVideo] = useState();
  /*  const [editor, setEditor] = useState({
    item_description: false,
    service_description: false,
    exchange_and_refund_description: false,
  }); */
  const [editor, setEditor] = useState(null);
  //Media

  const { request: getProductData, response: productDataResponse } =
    useRequest();

  const { response, request } = useRequest();
  const { request: getParentCategory, response: parentCategoryResponse } =
    useRequest();
  const { request: getCategoryLevel2, response: categoryLevel2Response } =
    useRequest();
  const { request: getCategoryLevel3, response: categoryLevel3Response } =
    useRequest();
  const { request: getActiveColors, response: activeColorsResponse } =
    useRequest();
  const { request: getActiveSizes, response: activeSizesResponse } =
    useRequest();
  const { request: getActiveFeatures, response: activeFeaturesResponse } =
    useRequest();
  const { request: getActiveBrands, response: activeBrandsResponse } =
    useRequest();

  useEffect(() => {
    document.title = "Edit-Product - Single Vendor";
    getParentCategory("GET", `category/activeCategories?parent_id=${0}`);
    //getActiveColors("GET", `color/active`);
    //getActiveSizes("GET", `size/active`);
    getActiveFeatures("GET", `feature/active`);
    getProductData("GET", `product/details/${id}`);
    getActiveBrands("GET", `brand/active`);
  }, []);

  useEffect(() => {
    if (!activeColorsResponse) return;
    let colors = activeColorsResponse.colors.map((color) => {
      return { label: color.name, value: color.id };
    });
    setColorsList(colors);
  }, [activeColorsResponse]);

  useEffect(() => {
    if (!activeSizesResponse) return;
    let sizes = activeSizesResponse.sizes.map((size) => {
      return { label: size.name, value: size.id };
    });
    setSizesList(sizes);
  }, [activeSizesResponse]);

  useEffect(() => {
    if (!activeFeaturesResponse) return;
    let features = activeFeaturesResponse.features.map((feature) => {
      return { label: feature.name, value: feature.id };
    });
    setFeaturesList(features);
  }, [activeFeaturesResponse]);

  useEffect(() => {
    if (!activeBrandsResponse) return;

    setBrandList(activeBrandsResponse.brands);
  }, [activeBrandsResponse]);

  useEffect(() => {
    if (!parentCategoryResponse) return;
    //console.log("in parent list");
    setParentCategoryInput(parentCategoryResponse.categories);
  }, [parentCategoryResponse]);

  useEffect(() => {
    if (response) {
      toast.success(response.message);
      history.push("/product");
    }
  }, [response]);

  useEffect(() => {
    if (!languages) return;
    languages.forEach((lang, index) => {
      register(`item_description-${lang.code}`, { required: true });
    });
  }, [languages]);

  //Media

  const onSubmitNew = (data) => {
    console.log("data:::::::::::::", data);
    if (images.length === 0) {
      return toast.error("Please upload image");
    }
    if (!data.variants.length) return toast.error("Please Add variants");

    if (
      !!data.warranty_years &&
      (!data[`service_description-en`] || !data[`service_description-ar`])
    )
      return toast.error(
        "Please fill Service and Warranty Description in both the languages"
      );

    if (
      !!data.return_days &&
      (!data[`exchange_and_refund_description-en`] ||
        !data[`exchange_and_refund_description-ar`])
    )
      return toast.error(
        "Please fill Returns and Exchanges Description in both the languages"
      );
    const formData = new FormData();
    formData.append("id", id);
    formData.append("parent_category", data.parent_category);
    formData.append("category_level_2", data.category_level_2);
    formData.append("category_level_3", data.category_level_3);
    formData.append("brand_id", data.brand_id);
    //formData.append("colors", JSON.stringify(data.colors));
    //formData.append("features", JSON.stringify(data.features));
    //formData.append("sizes", JSON.stringify(data.sizes));
    formData.append("price", data.price);
    formData.append("discount_price", data.discount_price);
    formData.append("stock_qty", data.stock_qty);
    formData.append("warranty_years", data.warranty_years);
    formData.append("return_days", data.return_days);
    formData.append("sku", data.sku);
    formData.append(
      "isDiscountPriceChanged",
      data.discount_price != productDataResponse?.product?.discount_price
    );
    // formData.append(
    //   "slug",
    //   `${data[`name-en`].replace(/ /g, "_")}-${Date.now()}`
    // );
    formData.append("name", data["name-en"]);

    formData.append("cover_image", coverImage);

    const selectedSizeImageFile = data["size_image"]?.[0];
    if (
      selectedSizeImageFile &&
      !selectedSizeImageFile.type.startsWith("image/")
    ) {
      toast.error("Please select a valid image file.");
      return;
    }

    if (selectedSizeImageFile && selectedSizeImageFile.size > FILE_MAX_SIZE) {
      toast.error("image size must be less than 5MB.");
      return;
    }

    if (selectedSizeImageFile)
      formData.append("size_image", selectedSizeImageFile);

    const selectedVideo = data["video"]?.[0];
    //console.log("ss", selectedVideo.type);
    if (selectedVideo && !selectedVideo.type.startsWith("video/")) {
      toast.error("Please select a valid video file.");
      return;
    }

    if (selectedVideo && selectedVideo.size > FILE_MAX_SIZE) {
      toast.error("video size must be less than 5MB.");
      return;
    }

    if (selectedVideo) formData.append("video", selectedVideo);

    if (data["og_image-en"] && data["og_image-en"][0])
      formData.append("og_image-en", data["og_image-en"][0]);
    if (data["og_image-ar"] && data["og_image-ar"][0])
      formData.append("og_image-ar", data["og_image-ar"][0]);

    const sumOfVariantsStocks = data.variants.reduce(
      (accumulator, currentItem) => {
        return accumulator + currentItem.stock_qty * 1;
      },
      0
    );
    if (data.stock_qty != sumOfVariantsStocks)
      return toast.error(
        "Total stock quantity of the variant should be equal to the stock quantity of the product."
      );

    /* if (data.variants)
      formData.append("variants", JSON.stringify(data.variants)); */

    const [addVariants, deleteVariants] = filterVariants(
      productDataResponse.variants,
      data.variants
    );
    //console.log("add", addVariants);
    //console.log("del", deleteVariants);
    if (data.variants) {
      formData.append("variants", JSON.stringify(data.variants));
      formData.append("delVariants", JSON.stringify(deleteVariants));
    }

    const uniqueColors = [...new Set(data.variants.map((v) => v.colorId))];
    const uniqueSizes = [...new Set(data.variants.map((v) => v.sizeId))];

    formData.append("colors", JSON.stringify(uniqueColors));
    formData.append("sizes", JSON.stringify(uniqueSizes));

    if (data.features)
      formData.append("features", JSON.stringify(data.features));

    const informationSubData = [];
    const descriptionSubData = [];

    for (let i = 0; i < languages.length; i++) {
      const id = languages[i].id;
      const code = languages[i].code;
      /* const infoDataEle = {
        language_id: id,
        lang_code: code,
        name: data[`name-${code}`],
        slug: slug,
      }; */
      const descDataEle = {
        language_id: id,
        lang_code: code,
        name: data[`name-${code}`],
        //small_description: data[`small_description-${code}`],
        item_description: data[`item_description-${code}`],
        service_description: data[`service_description-${code}`],
        exchange_and_refund_description:
          data[`exchange_and_refund_description-${code}`],
        title: data[`title-${code}`],
        meta_description: data[`meta_description-${code}`],
        meta_keywords: data[`meta_keywords-${code}`],
        twitter_card: data[`twitter_card-${code}`],
        twitter_site: data[`twitter_site-${code}`],
        og_url: data[`og_url-${code}`],
        og_type: data[`og_type-${code}`],
        og_title: data[`og_title-${code}`],
        og_description: data[`og_description-${code}`],
        meta_chronological: data[`meta_chronological-${code}`],
        id: langDataIds[code],
      };

      //informationSubData.push(infoDataEle);
      descriptionSubData.push(descDataEle);
    }
    formData.append("informationSubData", JSON.stringify(informationSubData));
    formData.append("descriptionSubData", JSON.stringify(descriptionSubData));
    //toast.success("form submit");
    request("PUT", "product", formData);
  };

  useEffect(() => {
    if (productDataResponse) {
      //console.log("in main edit");
      //console.log('edit', productDataResponse);
      const { product } = productDataResponse;

      if (!product) {
        history.push("/product");
        return;
      }

      getCategoryLevel2(
        "GET",
        `category/activeCategories?parent_id=${product.parent_category}`
      );
      getCategoryLevel3(
        "GET",
        `category/activeCategories?parent_id=${product.category_level_2}`
      );

      let languageDatas = product.languageData;
      const resetObj = {};
      const langDataIdsObj = {};
      //resetObj.color_code = product.color_code;
      //resetObj.image = product.image;
      setCoverImage(product.image);
      setSizeImage(product?.size_image);
      setProductVideo(product?.video);
      setImages([
        product.image,
        ...productDataResponse?.images?.map((img) => img.image),
      ]);
      for (let i = 0; i < languages.length; i++) {
        const id = languages[i].id;
        const code = languages[i].code;
        const currValue = getValueInLanguage(languageDatas, id);

        resetObj[`name-${code}`] = currValue.name;
        //resetObj[`title-${code}`] = currValue.title;
        //resetObj[`small_description-${code}`] = currValue.small_description;
        resetObj[`item_description-${code}`] = currValue.item_description;
        resetObj[`service_description-${code}`] = currValue.service_description;
        resetObj[`exchange_and_refund_description-${code}`] =
          currValue.exchange_and_refund_description;

        resetObj[`title-${code}`] = currValue.title;
        resetObj[`meta_description-${code}`] = currValue.meta_description;
        resetObj[`meta_keywords-${code}`] = currValue.meta_keywords;
        resetObj[`twitter_card-${code}`] = currValue.twitter_card;
        resetObj[`twitter_site-${code}`] = currValue.twitter_site;
        resetObj[`og_url-${code}`] = currValue.og_url;
        resetObj[`og_type-${code}`] = currValue.og_type;
        resetObj[`og_title-${code}`] = currValue.og_title;
        resetObj[`og_description-${code}`] = currValue.og_description;
        resetObj[`meta_chronological-${code}`] = currValue.meta_chronological;

        langDataIdsObj[code] = currValue.id;
      }

      resetObj.price = product.price;
      if (product.discount_price)
        resetObj.discount_price = product.discount_price;
      resetObj.stock_qty = product.stock_qty;
      resetObj.warranty_years = product.warranty_years;
      resetObj.return_days = product.return_days;
      resetObj.sku = product.sku;
      resetObj.parent_category = product.parent_category;

      resetObj.category_level_2 = product.category_level_2;

      resetObj.category_level_3 = product.category_level_3;
      changeCategoryL3(product.category_level_3);
      resetObj.brand_id = product.brand_id;

      const colorInitalValues = productDataResponse.colors.map((color) => {
        return { label: color.name, value: color.color_id };
      });
      resetObj.colors = colorInitalValues;

      const sizeInitalValues = productDataResponse.sizes.map((size) => {
        return { label: size.name, value: size.size_id };
      });

      resetObj.sizes = sizeInitalValues;

      const featureInitalValues = productDataResponse.features.map(
        (feature) => {
          return { label: feature.name, value: feature.features_id };
        }
      );

      resetObj.features = featureInitalValues;

      //console.log("ss", resetObj);
      let imageList = [product.image];

      productDataResponse.images.forEach((image) => {
        imageList.push(image.image);
      });

      let variants = productDataResponse.variants;
      //console.log(variants);
      const inputVarientList = [];
      if (variants && variants.length) {
        variants.forEach((variant) => {
          inputVarientList.push({
            label: `${variant.color}-${variant.size}`,
            colorId: variant.color_id,
            sizeId: variant.size_id,
            price: variant.price,
            discount_price: variant.discount_price,
            stock_qty: variant.stock_qty,
            sku: variant.sku,
            fileNames: variant.variant_image
              ? variant.variant_image.split(",")
              : [],
          });
        });
        //console.log("vl", inputVarientList);
        setShowAddVarient(true);
        setInputVariants(inputVarientList);
        resetObj.variants = inputVarientList;
      }
      //console.log(imageList);
      //console.log("res", resetObj);
      //console.log("conty", !!resetObj["item_description-en"]);
      setImages(imageList);
      setLangDataIds(langDataIdsObj);
      setEditor({
        item_description: !!resetObj["item_description-en"],
        service_description: !!resetObj["service_description-en"],
        exchange_and_refund_description:
          !!resetObj["exchange_and_refund_description-en"],
      });
      reset(resetObj);
      //console.log("initial", getValues());
      //setIsCkContent(true);
    }
  }, [productDataResponse]);

  function openTab(evt, tabName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " active";
  }

  const changeParentCategory = (value) => {
    setValue("category_level_2", "");
    setValue("category_level_3", "");
    setCategoryL2Input([]);
    setCategoryL3Input([]);
    if (!value) return;
    getCategoryLevel2("GET", `category/activeCategories?parent_id=${value}`);
    //console.log("change parent", getValues());
  };

  const changeCategoryL2 = (value) => {
    setValue("category_level_3", "");
    setCategoryL3Input([]);
    if (!value) return;
    getCategoryLevel3("GET", `category/activeCategories?parent_id=${value}`);
  };

  const changeCategoryL3 = (value) => {
    //console.log(value);
    const inputVarientList = [];
    setColorsList([]);
    setSizesList([]);
    setValue("colors", "");
    setValue("sizes", "");
    setValue("variants", inputVarientList);
    setInputVariants(inputVarientList);
    setShowAddVarient(inputVarientList.length ? true : false);
    if (!value) return;

    getActiveColors("GET", `category/${value}/active-colors`);
    getActiveSizes("GET", `category/${value}/active-sizes`);
  };

  useEffect(() => {
    if (!categoryLevel2Response) return;
    //console.log(categoryLevel2Response);
    setCategoryL2Input(categoryLevel2Response.categories);

    //console.log("in effect", getValues());
  }, [categoryLevel2Response]);

  useEffect(() => {
    if (!categoryLevel3Response) return;
    //console.log(categoryLevel2Response);
    setCategoryL3Input(categoryLevel3Response.categories);
  }, [categoryLevel3Response]);

  const ProductInformationInputFields = [
    [
      {
        Component: Input,
        label: "Name",
        name: "name",
        isRequired: true,
        registerFields: {
          required: true,
        },
        type: "text",
      },
    ],
  ];

  const ProductCommonInformationInputField = [
    {
      Component: CustomSelectInput,
      label: "Parent Category",
      name: "parent_category",
      registerFields: {
        required: true,
        onChange: (e) => {
          changeParentCategory(e.target.value);
        },
      },

      children: (
        <>
          <option value={""}>Select Parent Category</option>,
          {parentCategoryInput?.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
    {
      Component: CustomSelectInput,
      label: "Category Level 2",
      name: "category_level_2",
      registerFields: {
        required: true,
        onChange: (e) => {
          changeCategoryL2(e.target.value);
        },
      },
      children: (
        <>
          <option value={""}>Select Category</option>,
          {categoryL2Input?.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
    {
      Component: CustomSelectInput,
      label: "Category Level 3",
      name: "category_level_3",
      registerFields: {
        required: true,
        onChange: (e) => {
          changeCategoryL3(e.target.value);
        },
      },
      children: (
        <>
          <option value={""}>Select Category</option>,
          {categoryL3Input?.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
    {
      Component: CustomSelectInput,
      label: "Brand",
      name: "brand_id",
      registerFields: {
        required: true,
      },

      children: (
        <>
          <option value={""}>Select Brand</option>,
          {brandList?.map((brand) => (
            <option value={brand.id}>{brand.name}</option>
          ))}
        </>
      ),
    },
    {
      Component: Input,
      label: "Size image",
      name: "size_image",
      registerFields: {
        required: false,
      },
      isRequired: false,
      inputData: {
        accept: "image/*",
      },
      type: "file",
      image: sizeImage,
    },
    {
      Component: Input,
      label: "Product Video",
      name: "video",
      registerFields: {
        required: false,
      },
      isRequired: false,
      inputData: {
        accept: "video/*",
      },
      type: "file",
      video: productVideo,
    },
  ];

  const PriceInputFields = [
    {
      Component: Input,
      label: "Price",
      name: "price",
      registerFields: {
        required: true,
        min: 0,
      },
      type: "number",
    },
    {
      Component: Input,
      label: "Discount Price",
      name: "discount_price",
      registerFields: {
        required: false,
        min: 0,
        validate: {
          lessThanPrice: (value) => {
            const { price } = getValues();
            if (!value) return true;
            return (
              value * 1 < price * 1 || "Discount Price must be less than Price"
            );
          },
        },
      },
      type: "number",
    },
    {
      Component: Input,
      label: "Stock Quantity",
      name: "stock_qty",
      registerFields: {
        required: true,
        min: 0,
      },
      type: "number",
    },
    {
      Component: Input,
      label: "Warranty (in years)",
      name: "warranty_years",
      registerFields: {
        required: false,
        min: 0,
      },
      type: "number",
    },
    {
      Component: Input,
      label: "Return (in days)",
      name: "return_days",
      registerFields: {
        required: false,
        min: 0,
      },
      type: "number",
    },
    {
      Component: Input,
      label: "SKU",
      name: "sku",
      registerFields: {
        required: false,
      },
      type: "text",
    },
  ];

  //console.log("err", errors);
  //console.log("gv", getValues("item_description-en"));

  const DescriptionInputFields = [
    [
      {
        Component: CustomCKEditorInput,
        colClass: "col-xl-12",
        label: "Item Description",
        name: "item_description",
        registerFields: {
          required: true,
        },
        isRequired: true,
        getValues,
        setValue,
        trigger,
        inputData: {
          onInstanceReady: (editor) => {
            ckEditorRef.current = editor;
          },
        },
        clearErrors,
        setError,
        control,
      },
      {
        Component: CustomCKEditorInput,
        colClass: "col-xl-12",
        label: "Service and Warranty Description",
        name: "service_description",
        registerFields: {
          required: false,
        },
        isRequired: false,
        getValues,
        setValue,
        trigger,
        inputData: {
          onInstanceReady: (editor) => {
            ckEditorRef.current = editor;
          },
        },
        clearErrors,
        setError,
        control,
      },
      {
        Component: CustomCKEditorInput,
        colClass: "col-xl-12",
        label: "Returns and Exchanges Description",
        name: "exchange_and_refund_description",
        registerFields: {
          required: false,
        },
        isRequired: false,
        getValues,
        setValue,
        trigger,
        inputData: {
          onInstanceReady: (editor) => {
            ckEditorRef.current = editor;
          },
        },
        clearErrors,
        setError,
        control,
      },
    ],
  ];

  const seoInputFields = [
    [
      {
        Component: Input,
        label: "Title",
        name: "title",
        isRequired: true,
        registerFields: {
          required: true,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Meta Description",
        name: "meta_description",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Meta Keywords",
        name: "meta_keywords",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Twitter Card",
        name: "twitter_card",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Twitter Site",
        name: "twitter_site",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Og Url",
        name: "og_url",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Og Type",
        name: "og_type",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Og Title",
        name: "og_title",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Og Description",
        name: "og_description",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Meta Chronological",
        name: "meta_chronological",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "text",
      },
      {
        Component: Input,
        label: "Og Image",
        name: "og_image",
        isRequired: false,
        registerFields: {
          required: false,
        },
        type: "file",
        inputData: {
          accept: "image/*",
        },
      },
    ],
  ];

  const FeaturesInputField = [
    {
      Component: ReactSelectInputTwo,
      label: "Feature",
      name: "features",
      registerFields: {
        required: false,
      },
      options: featuresList,
      isMultiple: true,
      control: control,
    },
  ];

  const VarientInputField = [
    {
      Component: ReactSelectInputTwo,
      label: "Color",
      name: "colors",
      registerFields: {
        required: true,
      },
      options: colorsList,
      isMultiple: true,
      control: control,
    },
    {
      Component: ReactSelectInputTwo,
      label: "Size",
      name: "sizes",
      registerFields: {
        required: true,
      },
      options: sizesList,
      isMultiple: true,
      control: control,
    },
  ];

  const getVariantItem = (color_id, size_id) => {
    //console.log(productDataResponse);
    let variants = productDataResponse.variants;
    let variantItem = variants.find(
      (variant) => variant.color_id == color_id && variant.size_id == size_id
    );
    return variantItem;
  };

  const makeVarient = () => {
    const selectedColors = getValues("colors");
    const selectedSizes = getValues("sizes");
    const price = getValues("price");
    const discount_price = getValues("discount_price");
    //console.log(selectedColors);
    //console.log(selectedSizes);
    //if (!selectedColors || !selectedColors.length) return;
    //if (!selectedSizes || !selectedSizes.length) return;
    //unregister("variants");

    const inputVarientList = [];

    selectedColors.forEach((color) => {
      selectedSizes.forEach((size) => {
        let item = {
          label: `${color.label}-${size.label}`,
          colorId: color.value,
          sizeId: size.value,
        };
        const getData = getVariantItem(item.colorId, item.sizeId);
        inputVarientList.push({
          ...item,
          price: getData?.price || price,
          discount_price: getData?.discount_price || discount_price,
          stock_qty: getData?.stock_qty || 0,
          sku: getData?.sku || "",
          fileNames: getData?.variant_image
            ? getData.variant_image.split(",")
            : [],
        });
      });
    });
    //console.log("ii", inputVarientList);
    //let formData = { ...getValues() };
    //formData.variants = inputVarientList;
    //reset(formData);
    setValue("variants", inputVarientList);
    //console.log("form", getValues());
    setInputVariants(inputVarientList);
    setShowAddVarient(inputVarientList.length ? true : false);
  };

  const handleImageSelect = (e, index, file) => {
    //console.log("index", index);
    //console.log("file", file);
    const isChecked = e.target.checked;
    const newInputVarient = [...inputVarients];
    if (isChecked) {
      var newFiles = [...newInputVarient[index]?.fileNames, file];
    } else {
      var newFiles = newInputVarient[index]?.fileNames.filter(
        (fileName) => fileName != file
      );
    }
    newInputVarient[index].fileNames = newFiles;
    setValue("variants", newInputVarient);
    setInputVariants(newInputVarient);
    //console.log(newInputVarient);
  };

  useEffect(() => {
    document.getElementById("defaultOpen").click();
  }, []);

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Product"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/product", name: "Products" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <form onSubmit={handleSubmit(onSubmitNew)}>
            <div className="card card-custom gutter-b">
              <div className="card-body flex proSideTabAd">
                {/* <div className="row">
                  {COMMON.map((Input, index) => (
                    <Input.Component
                      key={index}
                      {...Input}
                      errors={errors}
                      register={register}
                      setValue={setValue}
                    />
                  ))}
                </div> */}

                <div className="tab">
                  <button
                    type="button"
                    className="tablinks"
                    onClick={(e) => openTab(e, "productInformation")}
                    id="defaultOpen"
                  >
                    {errors &&
                      (errors["name-ar"] ||
                        errors["name-en"] ||
                        errors["parent_category"] ||
                        errors["category_level_2"] ||
                        errors["category_level_3"] ||
                        errors["brand_id"]) && <InvalidTabSvg />}
                    Product Information
                  </button>
                  <button
                    className="tablinks"
                    type="button"
                    onClick={(e) => openTab(e, "prices")}
                  >
                    {errors &&
                      (errors["price"] ||
                        errors["discount_price"] ||
                        errors["stock_qty"] ||
                        errors["warranty_years"] ||
                        errors["return_days"]) && <InvalidTabSvg />}
                    Prices
                  </button>
                  <button
                    className="tablinks"
                    type="button"
                    onClick={(e) => openTab(e, "productImages")}
                  >
                    Product Images
                  </button>
                  <button
                    className="tablinks"
                    type="button"
                    onClick={(e) => openTab(e, "productDescriptions")}
                  >
                    {errors &&
                      (errors["item_description-ar"] ||
                        errors["item_description-en"]) && <InvalidTabSvg />}
                    Product Descriptions
                  </button>

                  <button
                    className="tablinks"
                    type="button"
                    onClick={(e) => openTab(e, "seoTags")}
                  >
                    {errors && (errors["title-ar"] || errors["title-en"]) && (
                      <InvalidTabSvg />
                    )}
                    SEO Tags
                  </button>

                  <button
                    className="tablinks"
                    type="button"
                    onClick={(e) => openTab(e, "productFeature")}
                  >
                    Product Features
                  </button>
                  <button
                    className="tablinks"
                    type="button"
                    onClick={(e) => openTab(e, "productVarient")}
                  >
                    {errors &&
                      (errors["colors"] ||
                        errors["sizes"] ||
                        errors["variants"]) && <InvalidTabSvg />}
                    Product Varient
                  </button>
                </div>

                <div id="productInformation" className="tabcontent">
                  <h3 className="mb-5 font-weight-bold text-dark">
                    Product Information
                  </h3>
                  <div className="card-header-tabs-line">
                    <div className="card-toolbar">
                      <ul
                        className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                        role="tablist"
                      >
                        {languages.length > 0 &&
                          languages.map((lang, index) => (
                            <SubTab
                              id="productInformation"
                              key={index}
                              name={lang.name}
                              index={index}
                              image={lang?.image}
                            />
                          ))}
                      </ul>
                    </div>
                  </div>
                  <div className="card-body px-0">
                    <div className="tab-content px-0">
                      {languages.length > 0 &&
                        languages.map((lang, index) => (
                          <>
                            <SubInput
                              id="productInformation"
                              key={index}
                              index={index}
                              errors={errors}
                              register={register}
                              InputFields={ProductInformationInputFields}
                              code={lang.code}
                              control={control}
                            />
                          </>
                        ))}
                    </div>
                    <button
                      onClick={handleSubmit(onSubmitNew)}
                      style={{ display: "none" }}
                    ></button>

                    <div className="row"></div>
                  </div>
                  <div className="row">
                    {categoryL2Input &&
                      categoryL3Input &&
                      ProductCommonInformationInputField.map((Input, index) => (
                        <Input.Component
                          key={index}
                          {...Input}
                          errors={errors}
                          register={register}
                          setValue={setValue}
                        />
                      ))}
                  </div>
                  {/* {sizeImage && (
                    <img
                      src={`${API.PORT}/${sizeImage}`}
                      width={100}
                      height={100}
                      alt=""
                      style={{ cursor: "pointer" }}
                      data-fancybox
                    />
                  )} */}
                </div>

                <div id="prices" className="tabcontent">
                  <h3 className="mb-5 font-weight-bold text-dark">
                    Price Information
                  </h3>
                  <div className="row">
                    {PriceInputFields.map((Input, index) => (
                      <Input.Component
                        key={index}
                        {...Input}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                      />
                    ))}
                  </div>
                </div>

                <div id="productImages" className="tabcontent">
                  <h3 className="mb-5 font-weight-bold text-dark">Images</h3>
                  {/* <EditImageUploader
                    productId={id}
                    images={images}
                    setImages={setImages}
                    coverImage={coverImage}
                    setCoverImage={setCoverImage}
                  /> */}
                  <TempEditImageUploader
                    productId={id}
                    images={images}
                    setImages={setImages}
                    coverImage={coverImage}
                    setCoverImage={setCoverImage}
                  />
                </div>
                <div id="productDescriptions" className="tabcontent">
                  <h3 className="mb-5 font-weight-bold text-dark">
                    Product Description
                  </h3>
                  <div className="card-header-tabs-line">
                    <div className="card-toolbar">
                      <ul
                        className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                        role="tablist"
                      >
                        {languages.length > 0 &&
                          languages.map((lang, index) => (
                            <SubTab
                              id="productDescriptions"
                              key={index}
                              name={lang.name}
                              index={index}
                              image={lang?.image}
                            />
                          ))}
                      </ul>
                    </div>
                  </div>
                  <div className="card-body px-0">
                    <div className="tab-content px-10">
                      {languages.length > 0 &&
                        languages.map((lang, index) => (
                          <>
                            {editor && (
                              <SubInput
                                id="productDescriptions"
                                key={index}
                                index={index}
                                errors={errors}
                                register={register}
                                InputFields={DescriptionInputFields}
                                code={lang.code}
                                control={control}
                              />
                            )}
                          </>
                        ))}
                    </div>
                    <button
                      onClick={handleSubmit(onSubmitNew)}
                      style={{ display: "none" }}
                    ></button>

                    <div className="row"></div>
                  </div>
                </div>
                <div id="seoTags" className="tabcontent">
                  <h3 className="mb-5 font-weight-bold text-dark">
                    Product Description
                  </h3>
                  <div className="card-header-tabs-line">
                    <div className="card-toolbar">
                      <ul
                        className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                        role="tablist"
                      >
                        {languages.length > 0 &&
                          languages.map((lang, index) => (
                            <SubTab
                              id="seoTags"
                              key={index}
                              name={lang.name}
                              index={index}
                              image={lang?.image}
                            />
                          ))}
                      </ul>
                    </div>
                  </div>
                  <div className="card-body px-0">
                    <div className="tab-content px-10">
                      {languages.length > 0 &&
                        languages.map((lang, index) => (
                          <>
                            <SubInput
                              id="seoTags"
                              key={index}
                              index={index}
                              errors={errors}
                              register={register}
                              InputFields={seoInputFields}
                              code={lang.code}
                            />
                          </>
                        ))}
                    </div>
                    <button
                      onClick={handleSubmit(onSubmitNew)}
                      style={{ display: "none" }}
                    ></button>

                    <div className="row"></div>
                  </div>
                </div>
                <div id="productFeature" className="tabcontent">
                  <h3 className="mb-5 font-weight-bold text-dark">
                    Product Features
                  </h3>
                  <div className="row">
                    {FeaturesInputField.map((Input, index) => (
                      <Input.Component
                        key={index}
                        {...Input}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                      />
                    ))}
                  </div>
                </div>
                <div id="productVarient" className="tabcontent">
                  <h3 className="mb-5 font-weight-bold text-dark">
                    Product Varients
                  </h3>
                  <div className="row">
                    {VarientInputField.map((Input, index) => (
                      <Input.Component
                        key={index}
                        {...Input}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                      />
                    ))}
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    id="variantbuttons"
                    onClick={makeVarient}
                  >
                    Make Variant
                  </button>
                  {showAddVarient && (
                    <div class="col-lg-12">
                      <div class="dataTables_wrapper">
                        <div class="table-responsive" id="varianttable">
                          <table class="table dataTable table-head-custom table-head-bg table-borderless table-vertical-center">
                            <thead>
                              <tr class="text-uppercase">
                                <th
                                  style={{
                                    minWidth: "150px",
                                    maxWidth: "150px",
                                  }}
                                >
                                  <a href="javascript:void(0);">Variant</a>
                                </th>
                                <th
                                  style={{
                                    minWidth: "150px",
                                    maxWidth: "150px",
                                  }}
                                >
                                  <a href="javascript:void(0);">Price</a>
                                </th>
                                <th
                                  style={{
                                    minWidth: "150px",
                                    maxWidth: "150px",
                                  }}
                                >
                                  <a href="javascript:void(0);">
                                    Discount Price
                                  </a>
                                </th>
                                <th
                                  style={{
                                    minWidth: "150px",
                                    maxWidth: "150px",
                                  }}
                                >
                                  <a href="javascript:void(0);">Stock QTY</a>
                                </th>
                                <th
                                  style={{
                                    minWidth: "150px",
                                    maxWidth: "150px",
                                  }}
                                >
                                  <a href="javascript:void(0);">SKU</a>
                                </th>
                                <th
                                  style={{
                                    minWidth: "150px",
                                    maxWidth: "150px",
                                  }}
                                >
                                  <a href="javascript:void(0);">Images</a>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {inputVarients.map((variant, index) => (
                                <tr>
                                  <td>
                                    {" "}
                                    <input
                                      {...register(`variants.${index}.colorId`)}
                                      value={variant.colorId}
                                      type="hidden"
                                    />{" "}
                                    <input
                                      {...register(`variants.${index}.sizeId`)}
                                      value={variant.sizeId}
                                      type="hidden"
                                    />{" "}
                                    {variant.label}
                                  </td>
                                  <td>
                                    <input
                                      class="form-control form-control-solid form-control-lg"
                                      {...register(`variants.${index}.price`, {
                                        required: "price is required",
                                        min: {
                                          value: 0,
                                          message: "price must be at least 0",
                                        },
                                      })}
                                      type="number"
                                      defaultValue={variant.price}
                                    />
                                    {errors.variants?.[index]?.price && (
                                      <span className="invalid-feedback">
                                        {errors.variants[index].price.message}
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      class="form-control form-control-solid form-control-lg"
                                      {...register(
                                        `variants.${index}.discount_price`,
                                        {
                                          min: {
                                            value: 0,
                                            message:
                                              "discount price must be at least 0",
                                          },
                                          validate: {
                                            lessThanPrice: (value) => {
                                              if (!value) return true;
                                              const price =
                                                getValues("variants")[index]
                                                  .price;
                                              return (
                                                value * 1 < price * 1 ||
                                                "Discount Price must be less than Price"
                                              );
                                            },
                                          },
                                        }
                                      )}
                                      type="number"
                                      defaultValue={variant.discount_price}
                                    />
                                    {errors.variants?.[index]
                                      ?.discount_price && (
                                      <span className="invalid-feedback">
                                        {
                                          errors.variants[index].discount_price
                                            .message
                                        }
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      class="form-control form-control-solid form-control-lg variant_stock_quantity"
                                      {...register(
                                        `variants.${index}.stock_qty`,
                                        {
                                          required: "stock Qty is required",
                                          min: {
                                            value: 0,
                                            message:
                                              "stock Qty must be at least 0",
                                          },
                                        }
                                      )}
                                      defaultValue={variant.stock_qty}
                                      type="number"
                                    />
                                    {errors.variants?.[index]?.stock_qty && (
                                      <span className="invalid-feedback">
                                        {
                                          errors.variants[index].stock_qty
                                            .message
                                        }
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      class="form-control form-control-solid form-control-lg"
                                      {...register(`variants.${index}.sku`)}
                                      type="text"
                                      defaultValue={variant.sku}
                                    />
                                  </td>
                                  <td>
                                    <div
                                      style={{ display: "flex", gap: "15px" }}
                                    >
                                      {images.map((image, imageIndex) => {
                                        // const imageUrl =
                                        //   URL.createObjectURL(file);
                                        return (
                                          <>
                                            <input
                                              type="checkbox"
                                              checked={variant.fileNames.includes(
                                                image
                                              )}
                                              onChange={(e) =>
                                                handleImageSelect(
                                                  e,
                                                  index,
                                                  image
                                                )
                                              }
                                            />
                                            <img
                                              src={`${API.PORT}/${image}`}
                                              width={100}
                                              height={100}
                                              alt=""
                                              style={{ cursor: "pointer" }}
                                              data-fancybox
                                            />
                                          </>
                                        );
                                      })}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      class="row variant_parent"
                                      style={{ flexWrap: "nowrap" }}
                                    ></div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <FormError errors={errors} />

                <SubmitButton
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmitNew}
                  name="Update"
                  pxClass="px-10"
                />
              </div>
            </div>

            {/* <div className="card card-custom gutter-b">
              <div className="card-header card-header-tabs-line">
                <div className="card-toolbar">
                  <ul
                    className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                    role="tablist"
                  >
                    {languages.length > 0 &&
                      languages.map((lang, index) => (
                        <SubTab
                          key={index}
                          name={lang.name}
                          index={index}
                          image={lang?.image}
                        />
                      ))}
                  </ul>
                </div>
              </div>

              <div className="card-body px-0">
                <div className="tab-content px-10">
                  {languages.length > 0 &&
                    languages.map((lang, index) => (
                      <>
                        <SubInput
                          key={index}
                          index={index}
                          errors={errors}
                          register={register}
                          required={true}
                          InputFields={InputFields}
                          code={lang.code}
                        />
                      </>
                    ))}
                </div>
                <button
                  onClick={handleSubmit(onSubmitNew)}
                  style={{ display: "none" }}
                ></button>

                <SubmitButton
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmitNew}
                  name="Add"
                  pxClass="px-10"
                />

                <div className="row"></div>
              </div>
            </div> */}
          </form>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </div>
  );
};

export default Edit;
