import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { SearchInput, SearchSubmitButton } from "../Form/Form";

const OBJ_TABLE = {
  "coupon code": "coupon_code",
  "Coupon type": "coupon_type",
  "offer amount": "offer_amount",
  "start time": "start_time",
  "end time": "end_time",
  "number of usage": "number_of_usage",
  "created on": "created_at",
};

const ViewAll = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(1);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "created on",
    order: "desc",
  });

  const MySwal = withReactContent(Swal);

  const { records_per_page } = useSelector((state) => state.setting);
  const { roleId, permission } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
  } = useForm();

  const { request, response } = useRequest();
  const { request: requestChangeStatus, response: responseChangeStatus } =
    useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Coupons- Single Vendor";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setData(response.coupons);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  useEffect(() => {
    if (responseChangeStatus) {
      const { id, newStatus } = responseChangeStatus;

      const oldData = [...data];
      const indexToChange = oldData.findIndex((ele) => ele.id == id);
      oldData[indexToChange].isActive = newStatus ? true : false;
      oldData[indexToChange].is_active = newStatus;
      toast.success(responseChangeStatus.message);
      setData(oldData);
    }
  }, [responseChangeStatus]);

  useEffect(() => {
    if (responseDelete) {
      const { id } = responseDelete;
      let newData = [...data];
      newData = newData.filter((ele) => ele.id != id);
      setData(newData);

      setTotalDocuments(totalDocuments - 1);
      // const pageCount = Math.ceil((totalDocuments) / perPage)

      // if(pageCount == page){
      //   const { question } = getValues();
      //   const newPage = page - 1;
      //   setPage(newPage);
      //   request(
      //     "GET",
      //     searchQueryHandler(
      //       newPage,
      //       perPage,
      //       currentSort.sortBy,
      //       currentSort.order,
      //       question
      //     )
      //   );
      // }
      toast.success("Coupon has been deleted successfully.");
    }
  }, [responseDelete]);

  const searchQueryHandler = (
    page,
    per_page,
    sortBy,
    order,
    searchText = ""
  ) => {
    if (sortBy.length > 0) {
      if (sortBy == "created on") {
        sortBy = "created_at";
      }
      if (sortBy == "coupon code") sortBy = "coupon_code";
      if (sortBy == "offer amount") sortBy = "offer_amount";
      if (sortBy == "start time") sortBy = "start_time";
      if (sortBy == "end time") sortBy = "end_time";
      if (sortBy == "number of usage") sortBy = "number_of_usage";
      if (sortBy == "discount type") sortBy = "discount_type";
    } else {
      sortBy = "created_at";
    }

    order = order.length > 0 ? order : "desc";

    return `coupon/all?page=${page}&per_page=${per_page}&sort_by=${sortBy}&order=${order}&code=${searchText}`;
  };

  const fetchMoreData = ({ selected }) => {
    setData([]);
    //state which is enter on search input
    const { searchText } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        searchText
      )
    );
  };

  const deleteHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete this coupon?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestDelete("DELETE", "coupon", { id });
      } else if (result.isDismissed) {
      }
    });
  };

  const onSearchHandler = (data) => {
    const { searchText } = getValues();
    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        searchText
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("searchText");
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const { searchText } = getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        searchText
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const { searchText } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(page, perPage, sortBy, newOrder, searchText)
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(page, perPage, sortBy, "desc", searchText)
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const changeStatusHandler = (id) => {
    const dataNew = data.find((ele) => ele.id == id);

    const status = dataNew.is_active == 1 ? 0 : 1;

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestChangeStatus("PUT", "coupon/status", {
          id,
          status,
        });
      } else if (result.isDismissed) {
      }
    });
  };

  //search input field
  const InputFields = [
    {
      label: "Discount Code",
      name: "searchText",
      required: true,
    },
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb title="Coupons" links={[{ to: "/", name: "Dashboard" }]} />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>

                    <Link to={`/coupon/add`} className="btn btn-primary">
                      Add New Coupon Code
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper faq-table">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={data}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      links={[
                        // {
                        //   isLink: false,
                        //   name: "Deactivate",
                        //   click: changeStatusHandler,
                        //   title: "Click To Activate",
                        //   key: "12_42",
                        // },
                        // {
                        //   isLink: false,
                        //   name: "Activate",
                        //   click: changeStatusHandler,
                        //   title: "Click To Deactivate",
                        //   key: "12_42",
                        // },

                        {
                          isLink: true,
                          to: `/coupon/edit`,
                          name: "Edit",
                          extraData: true,
                          key: "12_43",
                        },
                        {
                          isLink: true,
                          to: "/coupon-details",
                          name: "View",
                          extraData: true,
                          key: "12_45",
                        },

                        {
                          isLink: false,
                          name: "Delete",
                          click: deleteHandler,
                          key: "12_44",
                        },
                      ]}
                      onlyDate={{
                        created_at: "date",
                        start_time: "date",
                        end_time: "date",
                      }}
                      dontShowSort={["question", "answer"]}
                      renderAs={{
                        offer_amount: (value, id, data) => {
                          return data["coupon_type"] === "shipping" ? (
                            <span>Free Shipping</span>
                          ) : (
                            <span>
                              {value}
                              {data["discount_type"] === "percentage" && "%"}
                            </span>
                          );
                        },
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={data.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
