import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { SearchInput, SearchSubmitButton } from "../Form/Form";
import moment from "moment";
import { MasterObj } from "../../util/fn";

/* const statusChangeResponseMessage = {
  title: "Status of title is changed successfully",
  "cancel-reason": "Status of cancel reason  is changed successfully",
  "return-reason": "Status of return reason is changed successfully",
};
 */
const deleteResponseMessage = {
  title: "Title has been deleted successfully",
  "cancel-reason-user": "Cancel reason has been deleted successfully",
  "cancel-reason-admin": "Cancel reason has been deleted successfully",
  "return-reason": "Return reason has been deleted successfully",
};

const tableNameHeading = {
  title: "Title",
  "cancel-reason-user": "Cancel Reason",
  "cancel-reason-admin": "Cancel Reason",
  "return-reason": "Return Reason",
};

const ViewAll = (props) => {
  const { type } = props.match.params;

  const OBJ_TABLE = {
    [tableNameHeading[type]]: "code",
    "Created On": "created_at",
  };

  const [cms, setCms] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "Created On",
    order: "desc",
  });

  const { records_per_page } = useSelector((state) => state.setting);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
  } = useForm();

  const { request, response } = useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          page,
          records_per_page,
          currentSort.sortBy,
          currentSort.order,
          ""
        )
      );
    }
    document.title = "Master - Single Vendor";
  }, [records_per_page, type]);

  useEffect(() => {
    if (response) {
      //console.log("res", response);
      setCms(response.data);
      setTotalDocuments((prev) =>
        response.totalDocument ? response.totalDocument : prev
      );
    }
  }, [response]);

  useEffect(() => {
    if (responseDelete) {
      const { id } = responseDelete;
      let oldCms = [...cms];
      oldCms = oldCms.filter((cms) => cms.id != id);
      setCms(oldCms);
      setTotalDocuments((prev) => prev - 1);
      toast.success(deleteResponseMessage[type]);
    }
  }, [responseDelete]);

  function searchQueryHandler(page, per_page, sortBy, order, code = "") {
    sortBy =
      sortBy.length > 0
        ? sortBy === "Created On"
          ? "created_at"
          : sortBy
        : "created_at";
    order = order.length > 0 ? order : "desc";

    return `master/all?page=${page}&per_page=${per_page}&sortBy=${sortBy}&order=${order}&code=${code}&type=${type}`;
  }

  const fetchMoreData = ({ selected }) => {
    setCms([]);
    const { code } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        code
      )
    );
  };

  const deleteHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: `You want to delete this ${type}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestDelete("DELETE", "master", { id });
      } else if (result.isDismissed) {
      }
    });
  };

  const onSearchHandler = (data) => {
    const { code } = data;
    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        code
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("code");
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const { code } = getValues();
    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        code
      )
    );
    setPage(1);
    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    if (sortBy === "description") {
      return;
    }

    const { code } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request("GET", searchQueryHandler(page, perPage, sortBy, newOrder, code));
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request("GET", searchQueryHandler(page, perPage, sortBy, "desc", code));
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const InputFields = [
    {
      label: [tableNameHeading[type]],
      name: "code",
    },
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title={MasterObj[type]}
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>

                    <Link
                      to={`/master/${type}/add`}
                      className="btn btn-primary"
                    >
                      Add New {MasterObj[type]}
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={cms}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      renderAs={{
                        created_at: (val) => moment(val).format("DD-MM-YYYY"),
                      }}
                      links={[
                        {
                          isLink: true,
                          to: `/master/${type}/edit`,
                          name: "Edit",
                          access: ["e3"],
                        },
                        { isLink: false, name: "Delete", click: deleteHandler },
                      ]}
                      onlyDate={{
                        createdAt: "date",
                        startDate: "dateTime",
                        endDate: "dateTime",
                      }}
                      dontShowSort={["description"]}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={cms.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
